<template>
    <div>
        <gmap-autocomplete @place_changed="updateCenter($event)" />
        <GmapMap :center="centers" :zoom="70" map-type-id="terrain" style="width: 500px; height: 300px">
            <GmapMarker @dragend="updateMaker" :key="index" v-for="(m, index) in markers" :position="m.position"
                :clickable="true" :draggable="true" @click="center = m.position" />
        </GmapMap>
        <div v-for="item in googleList" class="dostance" @click="PostLocation(item)">
            <p style=""> {{ item.name }}
            </p>
            <div style="">
                <span>{{ item.distance }}</span>
                <span v-if="item.distance">|</span>
                <span>{{ item.vicinity }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { addressGooglist } from "@/api/member/member"
import router from '@/router';

export default {
    data() {
        return {
            centers: { lat: 0, lng: 0 },
            markers: [{
                position: { lat: 0, lng: 0 }
            }],
            googleList: [],
            form: ""
        }
    },
    created() {
        this.id = this.$route.query.id
        this.form = this.$route.query
        var _this = this;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                //locationSuccess 获取成功的话
                function (position) {
                    _this.markers[0].position.lng = position.coords.longitude;
                    _this.markers[0].position.lat = position.coords.latitude;
                    _this.centers.lng = position.coords.longitude;
                    _this.centers.lat = position.coords.latitude;
                    _this.getGoogleList()

                },
                //locationError  获取失败的话
                function (error) {
                    var errorType = ['您拒绝共享位置信息', '获取不到位置信息', '获取位置信息超时'];
                    alert(errorType[error.code - 1]);
                    this.getGoogleList()
                }
            )
        }
    },
    methods: {
        updateMaker: function (event) {
            this.markers[0].position = {
                lat: Number(this.markers[0].position.lat),
                lng: Number(this.markers[0].position.lng),
            }
        },
        updateCenter: function (place) {
            this.centers = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            }
            this.markers[0].position = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            }

            this.getGoogleList()
        },
        getGoogleList() {
            if (this.centers.lng == '' || this.centers.lat == '') {
                this.$message({
                    message: "定位失败",
                    type: "error"
                })
            }
            addressGooglist({
                longitude: Number(this.markers[0].position.lng),
                latitude: Number(this.markers[0].position.lat)
            })
                .then(res => {
                    this.googleList = []
                    res.results.forEach(item => {
                        var La1 = Number(this.centers.lat) * Math.PI / 180.0;
                        var La2 = item.geometry.location.lat * Math.PI / 180.0;
                        var La3 = La1 - La2;
                        var Lb3 = Number(this.centers.lng) * Math.PI / 180.0 - item.geometry.location.lng * Math.PI / 180.0;
                        var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(La3 / 2), 2) + Math.cos(La1) * Math.cos(La2) * Math.pow(Math.sin(Lb3 / 2), 2)));
                        s = s * 6378.137;//地球半径  
                        s = Math.round(s * 10000) / 10000;
                        let item1 = { ...item, ...{ distance: s + 'KM' } }
                        this.googleList.push(item1)
                    });
                    this.loading = false
                })
                .catch(err => {
                    this.googleList = []
                    err.results.forEach(item => {
                        console.log(item)
                        var La1 = Number(this.centers.lat) * Math.PI / 180.0;
                        var La2 = item.geometry.location.lat * Math.PI / 180.0;
                        var La3 = La1 - La2;
                        var Lb3 = Number(this.centers.lng) * Math.PI / 180.0 - item.geometry.location.lng * Math.PI / 180.0;
                        var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(La3 / 2), 2) + Math.cos(La1) * Math.cos(La2) * Math.pow(Math.sin(Lb3 / 2), 2)));
                        s = s * 6378.137;//地球半径  
                        s = Math.round(s * 10000) / 10000 * 1000;
                        if (s > 1000) {
                            s = ''
                        } else if (s < 100) {
                            s = "100m Within"
                        } else {
                            s = s.toFixed(2) + 'm'
                        }
                        let item1 = { ...item, ...{ distance: s } }
                        this.googleList.push(item1)
                    });
                    this.loading = false
                })
        },
        PostLocation(itm) {
            this.form.posLocation = itm.name
            if (this.id) {
                this.$router.replace({
                    path: '/member/address_edit', query: {
                        name: itm.name,
                        id: this.id
                    }
                });
            } else {
                this.$router.replace({
                    path: '/member/address_edit', query:
                        this.form
                });
            }

        }
    }
}
</script>
<style scoped lang="scss">
.pac-target-input {
    width: 480px;
    height: 25px;
    margin-bottom: 10px;
    text-align: center;
    border-radius: 8px;
}

.dostance {
    border: 1px solid #ccc;
    padding: 5px;

    >p {
        font-weight: 600;
        font-size: 16px;
        color: #000;
    }

    >div {
        font-size: 14px;

        span {
            &:nth-child(2) {
                padding: 0 8px;
            }

            color: #999999;
        }
    }
}
</style>